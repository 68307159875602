.cookie-bar {
	padding: 10px 20px;
	background: $color-brand;
	overflow: hidden;
	display: none;
	position: relative;
	z-index: 999;
	&__layout {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	&__text {
		@extend %copy;
		font-size: $base-font-size;
		padding-right: 20px;
		color: $white; }
	&__link {
		color: $white;
		transition: color .2s ease-in-out;
		&:hover {
			color: darken($color-brand, 15%); } }
	&__button {
		background: darken($color-brand, 7%);
		border: 1px solid darken($color-brand, 10%);
		transition: background .2s ease-in-out, border-color .2s ease-in-out;
		&:hover {
			border: 1px solid darken($color-brand, 20%);
			background: darken($color-brand, 15%); } }
	& + #outdated {
		border-top: 2px solid darken($color-brand, 7%); } }
