// @import 'node_modules/outdated-browser-rework/outdated-browser-rework.scss'

#outdated {
	background: $color-brand!important;
	padding: 20px 20px;
	text-align: center;
	position: relative;
	z-index: 999;
	opacity: 1!important;
	display: none;
	h6 {
		@extend %heading;
		font-size: 24px;
		font-weight: $bold;
		color: $white;
		margin-bottom: 20px; }
	p {
		@extend %copy;
		font-size: $base-font-size;
		color: $white;
		&.last {
			display: none; } } }
#buttonUpdateBrowser {
	@extend .button;
	display: block;
	max-width: 230px;
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	color: $white!important;
	background: darken($color-brand, 7%)!important;
	border: 1px solid darken($color-brand, 10%);
	transition: background .2s ease-in-out, border-color .2s ease-in-out;
	&:hover {
		color: $white!important;
		border-color: $color-brand, 20%;
		background: darken($color-brand, 15%)!important; } }
