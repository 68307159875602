.button {
	@extend %copy;
	border: 1px solid rgba($white, 0.9);
	padding: 20px 30px;
	margin-top: 20px;
	display: inline-block;
	text-decoration: none;
	transition: background ease-in-out 250ms, color ease-in-out 250ms;
	box-shadow: 5px 5px 20px rgba($black, .2);
	border-radius: 2px;
	&:hover {
		background: $white;
		color: $blue; } }
