html {
  font-size: 1em; }

body {
  font-family: $base-font;
  font-weight: 400;
  line-height: 1.45;
  color: #333; }

p {
  margin-bottom: 1.3em; }

h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
  font-family: $playfair; }

h1 {
  margin-top: 0;
  font-size: 6.854em; }

h2 {
  font-size: 4.236em; }

h3 {
  font-size: 2.618em; }

h4 {
  font-size: 1.618em; }

small, .font_small {
  font-size: 0.618em; }


@media (max-width: 567px) {
  html {
    font-size: 0.9em; } }
