// --------------------------------------------------------------------------
// colors.sass
// --------------------------------------------------------------------------
// This file assigns all hex colors to variables
// --------------------------------------------------------------------------
// All names are genereted using 'http://chir.ag/projects/name-that-color'
// --------------------------------------------------------------------------

//***********************************
// Greyscale
//***********************************

$black: #000;
$white: #fff;

$blue: #2800ff;
$black-pearl: #071525;
